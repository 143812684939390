<template>
    <div>
        <card-header title="Assigned Tasks" icon="fa-tasks"/>
        <card-body>
            <card-list>
                <subheader-list-item title="Registration Tasks" icon="fa-suitcase-rolling" :badge="tasks.assigned.registrations"/>

                <task-navigation-list-item
                        title="Flights"
                        icon="fa-plane"
                        :tasks="tasks.assigned.flights"
                        :active="child && child.definition.component === 'task-details' && child.props.type === 'flights'"
                        @click="$openCard('task-details', {type: 'flights'}, card)"
                />

                <task-navigation-list-item
                        title="Events"
                        icon="fa-users"
                        :tasks="tasks.assigned.reg_events"
                        :active="child && child.definition.component === 'task-details' && child.props.type === 'reg-events'"
                        @click="$openCard('task-details', {type: 'reg-events'}, card)"
                />

                <task-navigation-list-item
                    title="Add-Ons"
                    icon="fa-layer-plus"
                    :tasks="tasks.assigned.reg_addons"
                    :active="child && child.definition.component === 'task-details' && child.props.type === 'reg-addons'"
                    @click="$openCard('task-details', {type: 'reg-addons'}, card)"
                />

<!--                <navigation-list-item title="Questions" icon="fa-info-circle"/>-->
<!--                <navigation-list-item title="Billing" icon="fa-credit-card-front"/>-->

                <subheader-list-item title="Trip Tasks" icon="fa-tennis-ball" :badge="tasks.assigned.trips"/>

                <task-navigation-list-item
                    title="Events"
                    icon="fa-users"
                    :tasks="tasks.assigned.trip_events"
                    :active="child && child.definition.component === 'task-details' && child.props.type === 'trip-events'"
                    @click="$openCard('task-details', {type: 'trip-events'}, card)"
                />
                <task-navigation-list-item
                    title="Add-Ons"
                    icon="fa-layer-plus"
                    :tasks="tasks.assigned.trip_addons"
                    :active="child && child.definition.component === 'task-details' && child.props.type === 'trip-addons'"
                    @click="$openCard('task-details', {type: 'trip-addons'}, card)"
                />

                <!--                <subheader-list-item title="Other Tasks" icon="fa-clipboard-check"/>-->

            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardBody from "../../TIER/components/CardBody";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import {client as http} from "../../http_client";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import {mapActions, mapGetters} from 'vuex';
    import CardFooter from "../../TIER/components/CardFooter";
    import TaskNavigationListItem from "@/components/TaskNavigationListItem";

    export default {
        props: ['card', 'child'],
        components: {TaskNavigationListItem, SubheaderListItem, NavigationListItem, CardBody, CardList, CardHeader},
        data: function () {
            return {
            };
        },
        computed: {
            ...mapGetters(['tasks'])
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadTasks() {
                this.$emit('loading', true);
                this.updateTasks().then(() => {
                    this.$emit('loading', false);
                });
            },
            reload() {
                this.loadTasks();
            }
        },
        mounted() {
            this.loadTasks();
        }
    };
</script>

<style lang="scss" scoped>

</style>
